<template>
  <div class="info-panel-wrapper">
    <TitleContentPanel title="产业分析（税收占比）" height="100%" :marginBottom="0">
      <div class="chart-dom" ref="chartDom"></div>
    </TitleContentPanel>

    <div class="total-tax">总税收</div>
    <div class="part-tax">区税收</div>
  </div>
</template>

<script>
import TitleContentPanel from '@/components/TitleContentPanel'
import { onMounted, ref, computed } from 'vue'
import { formatTax, sixIndustryNames } from '@/utils/util'
import { aggregate } from '@/api/aggregate'
import * as echarts from 'echarts'
import { chartColors } from '@/config/commonConfig'
import { useStore } from 'vuex'

export default {
  name: 'InfoPanel',
  components: { TitleContentPanel },
  setup: () => {
    const store = useStore()
    const chartDom = ref()
    const currentYear = computed(() => store.state.common.currentYear)
    const buildingId = computed(() => store.state.common.currentBuildingId)
    const option = ref({
      backgroundColor: '#1a1a1a',
      color: chartColors,
      grid: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        show: true,
        backgroundColor: '#1a1a1a',
        borderColor: '#1a1a1a'
      },
      tooltip: {
        // trigger: 'item'
        show: false
      },
      legend: {
        itemGap: 15,
        itemWidth: 10,
        itemHeight: 10,
        // type: 'scroll',
        orient: 'vertical',
        left: 18,
        top: 'middle',
        // bottom: 0,
        icon: 'circle',
        textStyle: { color: '#fff' }
      },
      dataset: {
        source: []
      },
      series: [
        {
          minAngle: 5,
          name: '总税收',
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['60%', '70%'],
          label: {
            show: false,
            formatter: '{b} \n {d}%',
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '16'
              // fontWeight: 'bold'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          encode: {
            itemName: 'item',
            value: 'tax1'
          }
        },
        {
          minAngle: 5,
          name: '区税收',
          type: 'pie',
          center: ['80%', '50%'],
          radius: ['60%', '70%'],
          label: {
            formatter: '{b} \n {d}%',
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '16'
              // fontWeight: 'bold'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          encode: {
            itemName: 'item',
            value: 'tax2'
          }
        }
      ]
    })

    onMounted(async () => {
      const myChart = echarts.init(chartDom.value)
      const selected = {}

      const dataList = await getChartData(buildingId.value, currentYear.value)
      const values = sixIndustryNames.map(n => {
        const obj = dataList.find(d => d.id.industryTag === n)
        if (obj) {
          return [n, formatTax(obj.tax), formatTax(obj.qxTax)]
        } else {
          return [n, 0, 0]
        }
      })

      // values.sort((a, b) => b[1] - a[2])
      const temp = values.sort((a, b) => b[1] - a[2])
      const result = [['item', 'tax1', 'tax2'], ...temp]
      option.value.dataset.source = result

      if (result.length > 15) {
        values.forEach((n, i) => (selected[n[0]] = i < 13))
        option.value.legend.selected = selected
      } else {
        option.value.legend.selected = {}
      }

      myChart.on('legendselectchanged', e => {
        option.value.legend.selected = Object.keys(e.selected).map(d => ({
          [d]: true
        }))
        myChart.setOption(option.value)
      })

      myChart.setOption(option.value)
    })

    const getChartData = async (buildingId, year) => {
      const res = await aggregate({
        cmdList: [
          { $match: { 'building.id': `NumberLong(${buildingId})` } },
          { $match: { 'data.nf': `${year}年` } },
          { $unwind: '$industryTag' },
          { $match: { industryTag: { $in: sixIndustryNames } } },
          {
            $group: {
              _id: { industryTag: '$industryTag' },
              counts: { $sum: 1 },
              tax: { $sum: '$data.totalTax' },
              qxTax: { $sum: '$data.totalQxTax' }
            }
          }
        ],
        templateName: 'b_tax_information'
      })
      return res.data
    }

    getChartData(buildingId.value, currentYear.value).then(dataList => {
      sixIndustryNames.map(n => {
        const obj = dataList.find(d => d.id.industryTag === n)
        if (obj) {
          return [n, formatTax(obj.tax), formatTax(obj.qxTax)]
        } else {
          return [n, 0, 0]
        }
      })
    })

    return { chartDom }
  }
}
</script>

<style lang="less" scoped>
@valueColor: fade(#fff, 85%);
@labelColor: #92929d;
@bgc: #1c1c24;

.info-panel-wrapper {
  height: 284px;
  position: relative;

  .chart-dom {
    height: 100%;
  }

  .total-tax {
    position: absolute;
    left: 200px;
    top: 80px;
    color: @valueColor;
    font-size: 14px;
  }

  .part-tax {
    font-size: 14px;
    position: absolute;
    left: 420px;
    top: 80px;
    color: @valueColor;
  }
}
</style>
