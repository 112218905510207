<template>
  <div class="top-list-wrapper">
    <TitleContentPanel title="企业税收排名Top20" height="100%" :marginBottom="0">
      <RoomTabelList :dataList="dataList" :total="total" />
    </TitleContentPanel>
  </div>
</template>

<script>
import TitleContentPanel from '@/components/TitleContentPanel'
import RoomTabelList from './RoomTabelList.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { getRoomListByBuildingId } from '@/api/list'

export default {
  name: 'TopList',
  components: { TitleContentPanel, RoomTabelList },
  setup: () => {
    const dataList = ref([])
    const store = useStore()
    const buildingId = computed(() => store.state.common.currentBuildingId)
    const total = ref(0)

    getRoomListByBuildingId(buildingId.value).then(res => {
      dataList.value = res.data.list || []
      total.value = res.data.total
    })

    return { dataList, total }
  }
}
</script>

<style lang="less" scoped>
.top-list-wrapper {
  height: calc(100% - 115px);
}
</style>
