<template>
  <div class="tabs-list-layout-wrapper">
    <div class="tabs">
      <div
        class="tabs-item"
        :style="labelStyle"
        :class="{ active: index === currentIndex }"
        v-for="(tab, index) in tabList"
        :key="tab.label"
        @click="handleTabClick(tab, index)"
      >
        <span class="tabs-item-label">{{ tab.label }}</span>
      </div>

      <div class="more" v-if="industryLabel">
        {{ industryLabel }}
      </div>
    </div>

    <div class="filter">
      <slot name="filter" />
    </div>

    <div class="list">
      <slot name="list" />
    </div>
  </div>

  <Modal :isShow="showDetail">
    <div class="item-detail">
      <slot name="detail" />
    </div>
  </Modal>
</template>

<script>
import { ref } from 'vue'
import Modal from '@/components/Modal'
export default {
  name: 'TabsListLayout',
  components: { Modal },
  emits: ['onTabChange'],
  props: {
    industryLabel: {
      type: String,
      default: ''
    },
    tabList: {
      type: Array,
      default: () => []
    },
    labelStyle: {
      type: Object,
      default: () => ({})
    },
    showDetail: Boolean
  },
  setup: (props, { emit }) => {
    const currentIndex = ref(0)
    const isShow = ref(false)

    const handleTabClick = (item, index) => {
      currentIndex.value = index
      emit('onTabChange', item)
    }
    return { currentIndex, handleTabClick, isShow }
  }
}
</script>

<style lang="less" scoped>
@tabsBgc: #1a1a1a;
@tabsBgcActive: #1a1a1a;
@tabsLabelColor: fade(#fff, 50%);
@tabsLabelColorActive: fade(#fff, 85%);

.tabs-list-layout-wrapper {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;

  .tabs {
    display: flex;
    height: 70px;
    background-color: @tabsBgc;
    overflow: hidden;
    border-bottom: 1px solid @tabsBgc;
    justify-content: space-between;
    align-items: center;

    .tabs-item {
      flex: 1;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 1s;

      .tabs-item-label {
        transition: all 1s;
        color: @tabsLabelColor;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .more {
      color: #999;
      padding-right: 20px;
    }

    .active {
      transition: all 1s;
      background-color: @tabsBgcActive;
      .tabs-item-label {
        transition: all 1s;
        color: @tabsLabelColorActive;
      }
    }
  }

  .filter {
    height: 53px;
    background-color: @tabsBgcActive;
    border-bottom: 1px solid @tabsBgc;
    padding: 0 20px;
  }

  .list {
    // flex: 1;
    height: calc(100% - 70px - 53px);

    background-color: @tabsBgcActive;
    padding: 0 20px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
      height: 14px;
      // display: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1e75ff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      min-height: 28px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #161e31;
    }
  }
}

.item-detail {
  width: 1180px;
  height: 950px;
  background-color: #0b0f1a;
  padding: 20px;
  overflow: hidden;
}
</style>
