<template>
  <div class="label-count-wrapper">
    <CountTo
      class="count"
      :startVal="0"
      :endVal="count"
      :decimals="decimals"
      :duration="1000"
      :style="countStyle"
    ></CountTo>
    <div class="label">{{ label }}</div>
  </div>
</template>

<script>
import { CountTo } from 'vue-count-to2'
export default {
  name: 'LabelCount',
  components: { CountTo },
  props: {
    decimals: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    count: {
      type: [String, Number],
      default: ''
    },
    countStyle: {
      type: Object
    }
  },
  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
@labelColor: fade(#fff, 50%);
@countColor: #fff;

.label-count-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .label {
    color: @labelColor;
    font-size: 14px;
  }

  .count {
    margin-bottom: 10px;
    color: @countColor;
    font-size: 24px;
  }
}
</style>
