<template>
  <div class="image-vr-wrapper">
    <!-- <el-image :src="(imgList[0] && imgList[0].url) || defaultImg" fit="cover" /> -->
    <el-image
      style="width: 100%; height: 100%"
      :src="imgList[0] || defaultImg"
      :preview-src-list="imgList"
    />
    <div class="vr-btn" @click="handleVR" v-if="panoramaLink">
      <Icon class="btn-feiji" name="feiji" />
      <span class="vr-name">VR全景</span>
    </div>
  </div>

  <VRView ref="vr" :url="panoramaLink" />
</template>

<script>
import defaultImg from '@/assets/images/login_bg.png'
import Icon from '@/components/IconSvg'
import VRView from '@/components/VRView'
import { ref } from 'vue'
export default {
  name: 'ImageVR',
  components: { Icon, VRView },
  props: {
    imgList: Array,
    panoramaLink: String
  },
  setup: (props, { refs }) => {
    const vr = ref()
    const showVR = ref(false)

    const hanldeCloseBtn = () => {
      showVR.value = false
    }

    const handleVR = () => {
      vr.value.show()
    }
    return { defaultImg, vr, hanldeCloseBtn, handleVR }
  }
}
</script>

<style lang="less" scoped>
@textColor: fade(#fff, 85%);

.image-vr-wrapper {
  height: 248px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;

  .el-image {
    width: 100%;
    height: 100%;
  }

  .vr-btn {
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
    background-color: fade(#000, 20%);
    width: 100px;
    height: 30px;
    border-radius: 6px;

    .btn-feiji {
      width: 20px;
      height: 20px;
      color: @textColor;
      margin-right: 5px;
    }

    .vr-name {
      color: @textColor;
    }
  }
}
</style>
