<template>
  <div class="left-center-right-wrapper">
    <div class="left">
      <slot name="left" />
    </div>
    <div class="center">
      <slot name="center" />
    </div>
    <div class="right" v-if="slots.right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftCenterRight',
  setup: (props, { slots }) => {
    return { slots }
  }
}
</script>

<style lang="less" scoped>
.left-center-right-wrapper {
  height: 100%;
  display: flex;
  padding: 20px;

  .left {
    width: 400px;
    height: 100%;
    border-radius: 5px;
  }

  .right {
    border-radius: 5px;
    width: 400px;
    height: 100%;
  }

  .center {
    border-radius: 5px;
    flex: 1;
    // height: 100%;
    margin: 0 20px;
  }
}
</style>
