<template>
  <div class="top-list-wrapper">
    <TitleContentPanel title="企业税收排名Top20" height="100%" :marginBottom="0">
      <TaxTabelList :dataList="dataList" :total="total" @onPageNumChange="handlePageNumChange" />
    </TitleContentPanel>
  </div>
</template>

<script>
import TitleContentPanel from '@/components/TitleContentPanel'
import TaxTabelList from './TaxTabelList.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { getCompanyPageList } from '@/api/list'

export default {
  name: 'TopList',
  components: { TitleContentPanel, TaxTabelList },
  setup: () => {
    const dataList = ref([])
    const store = useStore()
    const buildingId = computed(() => store.state.common.currentBuildingId)
    // const mapBuildingGeojsonParams = computed(() => store.state.common.mapBuildingGeojsonParams)
    const pageNum = ref(1)
    const total = ref(0)

    const getChartData = async buildingId => {
      const res = await getCompanyPageList({
        conditions: { 'stats.regBuilding': `NumberLong(${buildingId})` },
        fields: {},
        sort: { 'stats.lastYearTax': -1 },
        pageInfo: {
          pageNum: pageNum.value,
          pageSize: 10
        }
      })

      return res.data
    }

    store.dispatch('getBuildingCompanyList', buildingId.value).then(res => {
      dataList.value = res.list
    })

    const handlePageNumChange = value => {
      pageNum.value = value
      getChartData(buildingId.value).then(res => {
        dataList.value = res.list
      })
    }

    return { dataList, total, handlePageNumChange }
  }
}
</script>

<style lang="less" scoped>
.top-list-wrapper {
  height: calc(100% - 115px);
}
</style>
