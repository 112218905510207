<template>
  <div class="info-panel-wrapper">
    <TitleContentPanel title="基础数据" height="100%" :marginBottom="0">
      <div class="top">
        <NumberUnitLabel
          class="top-part"
          :labelStyle="{ marginTop: '10px' }"
          label="闲置面积"
          unit="m²"
          :number="state.leaveArea"
        />
        <NumberUnitLabel
          class="top-part"
          :labelStyle="{ marginTop: '10px' }"
          label="单层最大面积"
          unit="m²"
          :number="state.maxFloorLeaveArea"
        />
        <NumberUnitLabel
          class="top-part"
          :labelStyle="{ marginTop: '10px' }"
          label="注册企业"
          unit="家"
          :number="state.regCpyCount"
        />
        <NumberUnitLabel
          class="top-part"
          :labelStyle="{ marginTop: '10px' }"
          label="入驻企业"
          unit="家"
          :number="state.enterCpyCount"
        />
      </div>

      <div class="bottom">
        <div class="addr bottom-item"><span class="label">地址：</span>{{ state.location }}</div>
        <div class="rent bottom-item"><span class="label">租金：</span>{{ state.rentInfo }}</div>
        <div class="sell bottom-item"><span class="label">售价：</span>{{ state.saleInfo }}</div>
        <div class="rent-sell bottom-item"></div>
        <!-- <div class="admin bottom-item"><span class="label">网格员：</span>{{ dataObj.admin }}</div> -->
      </div>
    </TitleContentPanel>
  </div>
</template>

<script>
import TitleContentPanel from '@/components/TitleContentPanel'
import NumberUnitLabel from '@/components/NumberUnitLabel/integer.vue'
import { useStore } from 'vuex'
import { computed, reactive, watch } from 'vue'

export default {
  name: 'InfoPanel',
  components: { TitleContentPanel, NumberUnitLabel },
  props: {
    dataObj: {
      type: Object,
      default: () => ({
        addr: '市南区香港路街道46号',
        rent: '4.7-5.9/元/天/平方米',
        sell: '34567元/平方米',
        admin: '黄多多'
      })
    }
  },
  setup: () => {
    const store = useStore()
    const state = reactive({})
    const buildingDetail = computed(() => store.state.common.currentBuildingDetail)

    watch(buildingDetail, detail => {
      state.leaveArea = detail.data.leaveArea
      state.maxFloorLeaveArea = detail.data.maxFloorLeaveArea
      state.regCpyCount = detail.stats.regCpyCount
      state.enterCpyCount = detail.stats.enterCpyCount
      state.location = detail.data.location
      state.rentInfo = detail.data.rentInfo ? `${detail.data.rentInfo} 元/㎡*天` : '暂无'
      state.saleInfo = detail.data.saleInfo ? `${detail.data.saleInfo} 元/㎡` : '暂无'
    })
    return { state }
  }
}
</script>

<style lang="less" scoped>
@valueColor: fade(#fff, 85%);
@labelColor: #92929d;
@bgc: #1c1c24;

.info-panel-wrapper {
  height: 263px;
  color: @valueColor;
  margin-bottom: 20px;

  .top {
    display: flex;
    justify-content: space-around;
    padding-bottom: 15px;
    border-bottom: 1px solid #292932;

    .top-part {
      // min-width: 25%;
    }
  }

  .bottom {
    color: #ffc542;
    font-size: 14px;
    padding: 15px 20px;

    .bottom-item {
      line-height: 2;
    }

    .label {
      color: @labelColor;
    }

    .rent-sell {
      display: flex;
      justify-content: space-between;
      .rent {
        // margin-right: 200px;
      }
    }
  }
}
</style>
