<template>
  <Modal :isShow="isShow">
    <div class="modal-box">
      <div class="title-box">
        <span class="title">市南区720全景</span>
        <Icon class="close" name="close" @click="isShow = false" />
      </div>
      <div class="content-box">
        <iframe
          :src="url"
          frameborder="0"
          width="100%"
          height="100%"
          allowFullScreen="true"
        ></iframe>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import Icon from '@/components/IconSvg'
import { ref } from 'vue'
export default {
  name: 'VRView',
  components: { Modal, Icon },
  props: {
    url: {
      type: String,
      default: 'https://720yun.com/t/01vktmf9r1m'
    }
  },
  setup: () => {
    const isShow = ref(false)
    const showCloseBtn = ref(false)

    const show = () => {
      isShow.value = true
    }

    const hid = () => {
      isShow.value = false
    }

    const hanldeCloseBtn = () => {
      hid()
    }

    return { isShow, show, hid, hanldeCloseBtn, showCloseBtn }
  }
}
</script>

<style lang="less" scoped>
@btnBgc: fade(#000, 30%);
@textColor: fade(#fff, 85%);
@titleBgc: #1b1c1c;
.modal-box {
  display: flex;
  flex-direction: column;

  .title-box {
    height: 45px;
    color: @textColor;
    background-color: @titleBgc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .close {
      width: 15px;
      height: 15px;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }

  .content-box {
    width: 1430px;
    height: 805px;
    & > iframe {
      width: 100%;
      height: 805px;
    }

    & > video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    & > video:focus {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
}
</style>
