<template>
  <div
    class="title-content-panel-wrapper"
    :class="{ active: active }"
    :style="{
      height: heightCss,
      marginBottom: `${marginBottom}px`
    }"
  >
    <div class="title-btn" :style="titleBoxStyle">
      <div
        class="title"
        v-for="(item, index) in tabs"
        :key="item.value"
        :class="{ active: current === index }"
        :style="tabsStyle"
        @click="handleClick(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
export default {
  name: 'TitleContentPanel',
  emits: ['onPanelClick'],
  props: {
    title: {
      type: String,
      default: '标题'
    },
    height: {
      type: [Number, String],
      default: 227
    },
    marginBottom: {
      type: Number,
      default: 20
    },
    titleStyle: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    },
    titleBoxStyle: {
      type: Object,
      default: () => ({})
    },
    tabs: {
      type: Object,
      default: () => ({})
    },
    tabsStyle: {
      type: Object,
      default: () => ({})
    }
  },
  setup: (props, { emit }) => {
    const current = ref(0)
    const heightCss = computed(() =>
      typeof props.height === 'string' ? props.height : `${props.height}px `
    )

    const handleClick = (item, index) => {
      current.value = index
      emit('onPanelClick', item.value)
    }

    return { heightCss, current, handleClick }
  }
}
</script>

<style lang="less" scoped>
@titleColor: fade(#fff, 85%);
@bgcColor: #1a1a1a;
@titleH: 75px;
@activeColor: #1885ff;

.title-content-panel-wrapper {
  background-color: @bgcColor;
  transition: all 0.5s;
  border: 1px solid transparent;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;

  & > .title-btn {
    height: @titleH;
    color: @titleColor;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @bgcColor;
    cursor: pointer;

    .title {
      // font-weight: bold;
      font-size: 16px;
    }
  }

  & > .content {
    // padding: 20px;
    height: calc(100% - @titleH);
  }
}

.active {
  transition: all 0.5s;
  color: @activeColor;
}
</style>
