<template>
  <div class="label-value-unit-wrapper">
    <div class="label">{{ label }}</div>
    <div class="value-unit">
      <div class="value">{{ value }}</div>
      <div class="unit">{{ unit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabelValueUnit',
  props: {
    label: String,
    value: [Number, String],
    unit: String
  },
  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
.label-value-unit-wrapper {
  flex: 1;
  width: 1px;
  .label {
    margin-bottom: 10px;
    color: #92929d;
    font-size: 14px;
  }

  .value-unit {
    color: #fff;
    font-size: 16px;

    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
</style>
