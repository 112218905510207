<template>
  <div class="building-part-wrapper">
    <InfoTax />
    <BuildingTopList />
  </div>
</template>

<script>
import InfoTax from './BuildingInfoTax.vue'
import BuildingTopList from './BuildingTopList.vue'
export default {
  name: '',
  components: { InfoTax, BuildingTopList },
  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
.building-part-wrapper {
  height: 100%;
}
</style>
