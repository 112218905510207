<template>
  <div class="list-sort-wrapper">
    <div
      class="list-item"
      :class="{ active: index === currentIndex }"
      v-for="(item, index) in state.dataList"
      :key="item.label"
      @click="handleItemClick(item, index)"
    >
      <div class="label">{{ item.label }}</div>
      <div class="btn">
        <Icon
          name="bottomArrow"
          class="arrow"
          :class="{ bottomArrowActive: index === currentIndex && item.sort === -1 }"
        />
        <Icon
          name="topArrow"
          class="arrow"
          :class="{ bottomArrowActive: index === currentIndex && item.sort === 1 }"
        />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { reactive, ref, toRaw, watch } from 'vue'
import Icon from '@/components/IconSvg'
export default {
  name: 'ListSort',
  components: { Icon },
  emits: ['onSort'],
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  setup: (props, { emit }) => {
    const currentIndex = ref(-1)

    // 为了解决上下箭头的响应式
    const state = reactive({
      dataList: toRaw(props.dataList)
    })
    //
    const handleItemClick = (item, index) => {
      if (currentIndex.value === index) {
        item.sort = item.sort === 1 ? -1 : 1
      } else {
        currentIndex.value = index
        item.sort = -1
      }

      emit('onSort', item)
    }
    return { currentIndex, handleItemClick, state }
  }
}
</script>

<style lang="less" scoped>
@commonColor: #b5b5be;
@activeColor: #1e75ff;

.list-sort-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .list-item {
    display: flex;
    cursor: pointer;

    & > .label {
      color: @commonColor;
      font-size: 16px;
    }

    & > .btn {
      display: flex;

      .arrow {
        width: 16px;
        height: 16px;
        color: @commonColor;
        margin-right: -8px;
      }
    }
  }

  .active {
    .label {
      color: @activeColor;
    }
  }

  .bottomArrowActive {
    color: @activeColor !important;
  }
}
</style>
