<template>
  <div class="room-part-wrapper">
    <RoomInfoTax />
    <RoomTopList />
  </div>
</template>

<script>
import RoomInfoTax from './RoomInfoTax.vue'
import RoomTopList from './RoomTopList.vue'
export default {
  name: '',
  components: { RoomInfoTax, RoomTopList },
  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
.room-part-wrapper {
  height: 100%;
}
</style>
