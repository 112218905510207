<template>
  <div
    class="title-content-panel-wrapper"
    :class="{ active: active }"
    :style="{
      height: heightCss,
      marginBottom: `${marginBottom}px`
    }"
    @click="hanldePanelClick"
  >
    <div class="title-btn" :style="titleBoxStyle">
      <div class="title" :style="titleStyle">{{ title }}</div>
      <div class="btn">
        <slot name="append" />
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
    <div class="reset">
      <slot name="reset" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'TitleContentPanel',
  emits: ['onPanelClick'],
  props: {
    title: {
      type: String,
      default: '标题'
    },
    height: {
      type: [Number, String],
      default: 227
    },
    marginBottom: {
      type: Number,
      default: 20
    },
    titleStyle: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    },
    titleBoxStyle: {
      type: Object,
      default: () => ({})
    }
  },
  setup: (props, { emit }) => {
    const heightCss = computed(() =>
      typeof props.height === 'string' ? props.height : `${props.height}px `
    )

    const hanldePanelClick = () => {
      emit('onPanelClick')
    }
    return { heightCss, hanldePanelClick }
  }
}
</script>

<style lang="less" scoped>
@titleColor: fade(#fff, 85%);
@bgcColor: #1a1a1a;
@titleH: 75px;
@activeColor: #1885ff;

.title-content-panel-wrapper {
  position: relative;
  background-color: @bgcColor;
  transition: all 0.5s;
  border: 1px solid transparent;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;

  & > .title-btn {
    height: @titleH;
    color: @titleColor;
    cursor: pointer;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @bgcColor;

    .title {
      // font-weight: bold;
      font-size: 16px;
    }
  }

  & > .content {
    // padding: 20px;
    height: calc(100% - @titleH);
  }

  & > .reset {
    position: absolute;
    bottom: 0;
    right: 20px;
  }
}

.active {
  border: 1px solid @activeColor;
  transition: all 0.5s;
  & > .title-btn {
    transition: all 0.5s;
    background-color: #0e1421;
    color: @activeColor;
  }
}
</style>
