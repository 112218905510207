<template>
  <div class="info-tax-wrapper">
    <div class="list">
      <div class="list-item" v-for="item in dataList" :key="item.id">
        <div class="left">
          <div class="arrow" :class="{ plus: item.change > 0 }">
            <i class="el-icon-top"></i>
          </div>
        </div>
        <div class="right">
          <NumberUnitLabel
            :label="item.label"
            :change="item.change"
            :number="item.value"
            :labelStyle="{ marginTop: '10px' }"
            :unitStyle="{ color: '#fff' }"
            :unit="item.unit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import NumberUnitLabel from '@/components/NumberUnitLabel/integer.vue'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { formatTax } from '@/utils/util'
import { aggregate } from '@/api/aggregate'
import { getRoomListByBuildingId } from '@/api/list'

const defaultDataList = [
  {
    id: 0,
    label: '房源总数',
    change: 1,
    value: 0,
    key: 'totalCount',
    unit: '个'
  },
  {
    id: 1,
    label: '房源面积',
    change: 1,
    value: 0,
    key: 'totalArea',
    unit: '㎡'
  }
]

export default {
  name: 'InfoTax',
  props: {},
  components: { NumberUnitLabel },
  setup: () => {
    const store = useStore()
    const dataList = ref([])
    const buildingId = computed(() => store.state.common.currentBuildingId)
    // const buildingDetail = computed(() => store.state.common.currentBuildingDetail)
    // const buildingCompanyList = computed(() => store.state.common.buildingCompanyList)

    const fetchData = buildingId => {
      const params = {
        cmdList: [
          { $match: { 'data.registerBuilding': `NumberLong(${buildingId})` } },
          {
            $group: {
              _id: 'null',
              totalCount: { $sum: 1 },
              totalArea: { $sum: '$data.buildingArea' }
            }
          }
        ],
        templateName: 'renting_information'
      }

      aggregate(params).then(res => {
        console.log(res)
        const obj = res.data[0] || {}
        dataList.value = defaultDataList.map(d => {
          d.value = obj[d.key] || 0
          return d
        })
      })
    }

    fetchData(buildingId.value)

    // watch(buildingDetail, detail => {
    //   dataList.value = defaultDataList.map(d => {
    //     d.value = formatTax(detail.stats[d.key]) || 0
    //     return d
    //   })
    // })

    // watch(buildingCompanyList, bcl => {
    //   console.log(bcl.group)
    //   dataList.value = defaultDataList.map(d => {
    //     d.value = formatTax(bcl.group[0][d.key]) || 0
    //     return d
    //   })
    // })
    // regCurrentYearQxTax: 0
    // regCurrentYearTax: 0
    // regLastYearQxTax: 1188087.8694000002
    // regLastYearTax: 2458951.4099999997

    return { dataList }
  }
}
</script>

<style lang="less" scoped>
.info-tax-wrapper {
  height: 122px;
  .list {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;

    .list-item {
      display: flex;
      background-color: #292932;
      padding: 20px;
      width: 248px;
      height: 102px;
      border-radius: 20px;
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .left {
        margin-right: 15px;

        .arrow {
          width: 36px;
          height: 36px;
          background-color: rgba(61, 213, 152, 0.25);
          // line-height: 30px;
          // text-align: center;
          border-radius: 10px;
          color: #3dd598;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .plus {
          color: hsl(0, 96%, 67%);
          background-color: rgba(252, 90, 90, 0.25);
        }
      }

      .right {
        flex: 1;
      }
    }
  }
}
</style>
