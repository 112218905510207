<template>
  <div class="building-detail-wrapper">
    <div class="title-close">
      <span class="title">{{ state.name }}</span>

      <div class="net-admin-btn">
        <Icon class="close" name="close" @click="hanldeCloseBtn" />
      </div>
    </div>

    <div class="detail-content">
      <div class="left">
        <ImageVR :imgList="state.imgList" :panoramaLink="state.panoramaLink" />
        <InfoPanel />
        <InfoChart />
      </div>
      <div class="right">
        <TitleContentPanel
          :tabs="tabs"
          height="100%"
          :marginBottom="0"
          :titleBoxStyle="{
            justifyContent: 'flex-start'
          }"
          :tabsStyle="{
            marginRight: '20px'
          }"
          @onPanelClick="handleItemClick"
        >
          <component :is="state.currentName" />
        </TitleContentPanel>
      </div>
    </div>
  </div>
</template>

<script>
// import BaseInfo from './BaseInfo'
// import FloorList from './FloorList'
// import RoomList from './RoomList'
import ImageVR from './ImageVR.vue'
import Icon from '@/components/IconSvg'
import InfoPanel from './InfoPanel.vue'
import InfoChart from './InfoChart.vue'

import { useStore } from 'vuex'
import { computed, watch, reactive } from 'vue'
import TitleContentPanel from '@/components/TitleContentPanel/tabs.vue'
import BuildingPart from './BuildingPart'
import RoomPart from './RoomPart'
const tabs = [
  {
    label: '楼宇税收',
    value: 1
  },
  {
    label: '房源统计',
    value: 2
  }
]
export default {
  name: 'BuildingDetail',
  components: { TitleContentPanel, Icon, ImageVR, InfoPanel, InfoChart, BuildingPart, RoomPart },
  setup: (props, { emit }) => {
    const store = useStore()
    const buildingId = computed(() => store.state.common.currentBuildingId)
    const buildingDetail = computed(() => store.state.common.currentBuildingDetail)
    const state = reactive({
      imgList: [],
      currentName: 'BuildingPart'
    })

    watch(buildingDetail, detail => {
      state.name = detail.data.name
      state.imgList = detail.data.imgList ? detail.data.imgList.map(d => d.url) : []
      state.panoramaLink = detail.data.panoramaLink
    })

    watch(
      buildingId,
      id => {
        if (id) {
          store.dispatch('getBuildingDetail', id)
        }
      },
      { immediate: true }
    )

    const hanldeCloseBtn = () => {
      emit('onClose')
    }

    const handleItemClick = val => {
      const map = {
        1: 'BuildingPart',
        2: 'RoomPart'
      }
      state.currentName = map[val]
    }

    return { state, hanldeCloseBtn, buildingDetail, tabs, handleItemClick }
  }
}
</script>

<style lang="less" scoped>
@bgcColor: #1c1c24;
@textColor: fade(#fff, 85%);
@titleBgc: #1b1c1c;

.building-detail-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title-close {
    height: 75px;
    color: @textColor;
    // background-color: @titleBgc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 24px;
    padding-left: 20px;

    .net-admin-btn {
      display: flex;
      align-items: center;

      .net-admin {
        margin-right: 42px;
        font-size: 14px;
        color: @textColor;
      }

      .vr-btn {
        display: flex;
        font-size: 14px;
        align-items: center;
        margin-right: 42px;

        .btn-feiji {
          width: 20px;
          height: 20px;
          color: @textColor;
          margin-right: 5px;
        }
      }

      .close {
        position: relative;
        top: -5px;
        width: 20px;
        height: 20px;
        color: @textColor;
        cursor: pointer;
      }
    }
  }

  .detail-content {
    display: flex;
    flex: 1;
    height: 1px;

    .left {
      flex: 1;
      margin-right: 20px;
      height: 100%;
      border-radius: 20px;
    }

    .right {
      flex: 1;
      height: 100%;
      background-color: #1c1c24;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
