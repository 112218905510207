<template>
  <div class="tax-tabel-list-wrapper">
    <div class="table-box">
      <div class="table-header">
        <el-row>
          <el-col :span="14">房源名称</el-col>
          <el-col :span="4">租售</el-col>
          <el-col :span="4">面积（㎡）</el-col>
        </el-row>
      </div>
      <div class="table-list">
        <el-row v-for="item in dataList" :key="item.name" class="list-item">
          <el-col :span="14" class="name-box">
            <div class="name-text">{{ item.data.name }}</div>
          </el-col>
          <el-col :span="4" class="y2021">
            <div class="y2021-value">
              <div>{{ item.data.typeLabel }}</div>
            </div>
          </el-col>
          <el-col :span="4" class="y2021">
            <div>{{ item.data.buildingArea }}</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// import { CountTo } from 'vue-count-to2'
import { formatTax } from '@/utils/util'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'TaxTabelList',
  components: {},
  props: {
    dataList: Array,
    total: Number
  },
  setup: (props, { emit }) => {
    const store = useStore()
    const mapBuildingGeojsonParams = computed(() => store.state.common.mapBuildingGeojsonParams)
    const handlePageChange = value => {
      emit('onPageNumChange', value)
    }
    return { formatTax, handlePageChange, mapBuildingGeojsonParams }
  }
}
</script>

<style lang="less" scoped>
@hoverColor: lighten(#1c1c24, 10%);
@tableH: calc(100% - 15px);
@tableListH: calc(100% - 40px);

.active {
  background-color: @hoverColor;
}

.tax-tabel-list-wrapper {
  padding: 0 20px 0;
  height: 100%;

  .title-box {
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #fafafb;
  }

  .table-box {
    height: @tableH;

    &::-webkit-scrollbar {
      display: none;
    }

    .table-header {
      height: 40px;
      line-height: 40px;
      color: #92929d;
    }

    .table-list {
      color: #92929d;
      height: calc(100% - 55px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
        height: 14px;
        // display: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #0e1421;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        min-height: 28px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: #161e31;
      }

      .list-item {
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;

        .name-box {
          position: relative;
          .name-text {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fafafb;
          }

          .up-bgc {
            background-color: fade(#f75859, 20%);
          }

          .up-text {
            color: #fc5a5a;
          }
        }

        .y2021 {
          display: flex;

          .y2021-value {
            margin-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .change {
      display: flex;
      align-items: center;
      color: #3dd598;
    }

    .plus {
      color: #e95556;
    }
  }

  .pagination {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(.btn-prev) {
      background-color: #27324d;
      color: #fafafb;
    }

    :deep(.btn-next) {
      background-color: #27324d;
      color: #fafafb;
    }

    :deep(.el-pager li) {
      background-color: #27324d;
      color: #fafafb;
    }
  }
}
</style>
