<template>
  <div class="number-unit-label-wrapper">
    <div class="number-unit">
      <CountTo class="number" :startVal="0" :endVal="number" :duration="1000" :decimals="2" />
      <div class="unit" :style="unitStyle" v-if="unit">{{ unit }}</div>
      <!-- <div class="change" :class="{ plus: change > 0 }" v-if="change != undefined">
        <div class="value">
          {{ change > 0 ? `+${change}%` : `${change}%` }}
        </div>
      </div> -->
    </div>
    <div class="label" :style="labelStyle">{{ label }}</div>
  </div>
</template>

<script>
import { CountTo } from 'vue-count-to2'
export default {
  name: 'NumberUnitLabel',
  components: { CountTo },
  props: {
    number: {
      type: [Number, String],
      default: 1000
    },
    unit: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '标题'
    },
    change: {
      type: Number
    },
    labelStyle: {
      type: Object
    },
    unitStyle: {
      type: Object
    }
  },
  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
.number-unit-label-wrapper {
  .number-unit {
    display: flex;
    align-items: flex-start;

    .number {
      font-size: 28px;
      margin-right: 10px;
      // font-weight: 600;
      color: #fafafb;
      // font-weight: 700;
      // background-image: linear-gradient(25deg, #3b90f9 43%, #00c8f4 92%);
      // background-image: linear-gradient(25deg, #5bf9c4 43%, #00fd7b 92%);
      // background-clip: text;
      // color: transparent;
      // letter-spacing: -2px;
      // transform: scale(0.85, 1.1);
    }

    .unit {
      margin-top: 10px;
      font-size: 14px;

      // font-weight: bold;
    }
  }

  .label {
    margin-top: 20px;
    font-size: 14px;
    color: #696974;
  }

  .change {
    display: flex;
    height: 28px;
    color: #3dd598;
    align-items: center;
  }

  .plus {
    color: #e95556;
  }
}
</style>
